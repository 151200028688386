import {
    Box,
    Grid,
  
    Stack,
    Typography,
  } from "@mui/material";
  
  export default function TermsView() {
    return (
      <Box sx={{ margin: "auto" }}>
        <Grid container margin={0} direction="column">        
        <Stack component="section" direction="column" className="white legal" padding={2}>
            <Box className="text" >
            
            <h1>Nucleus Technologies Inc. Terms of Use</h1>
            <p><strong>Last Updated: September 18, 2024</strong></p>
<h2><u>Acceptance of Terms</u></h2>
<p>
This Website, offered on or through <u><a href='http://www.nucleus.com'>www.nucleus.com</a></u> (the "<b>Website</b>"), and the services (<b>Appendix A</b>) that Nucleus Technologies Inc. (“<b>Nucleus”</b>) provides to you are subject to the following Terms of Use ("<b>TOU</b>").</p>
<p>
Through its network of Web properties, Nucleus provides you with access to a variety of resources, including developer tools, download areas, communication forums and product information (collectively "Services"). The Services, including any updates, enhancements, new features, and/or the addition of any new Web properties, are subject to these TOU.</p>
<p>
We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of this Website and/or Services. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.</p>
<p>
The information and material on this Website and/or Services may be changed, withdrawn, or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website and/or Services is restricted to users or unavailable at any time or for any period.</p>

<h2><u>Personal and Limitation of Use</u></h2>
<p>
Unless otherwise specified, this Website and/or Services are for your personal use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from this Website and/or Services.</p>

<h2><u>Privacy and Protection of Personal Information</u></h2>
<p>
Review the Privacy Policy on this Website, or by clicking here, relating to the collection and use of your personal information.</p>

<h2><u>Your Use of the Website and/or Services, and Account Set-Up and Security</u></h2>

<p>
The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
<p>
The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Website and/or Services. Users are required to ensure that all persons who access the Website and/or Services through a user's internet connection are aware of this TOU and comply with them. The Website, including content or areas of the Website, and/or the Services, may require user registration. It is a condition of your use of the Website and/or Services that all the information you provide is correct, current, and complete.</p>
<p>
Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website and/or Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website and/or Services.</p>
<p>
Your provision of registration information and any submissions you make to the Website and/or Services through any functionality such as, but not limited to, applications, chat rooms, e-mail, message boards, personal, or interest group web pages, profiles, forums, bulletin boards, and other such functions (collectively, "<b>Interactive Functions</b>") constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy, found on this Website.</p>
<p>
You are prohibited from attempting to circumvent and from violating the security of this Website and/or Services, including, without limitation:</p>

<ol type="a">
<li>violating any applicable federal, state, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or containing any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with this TOU and our Privacy Policy;</li>
<li>accessing content and data that is not intended for you;</li>
<li>attempting to breach or breaching the security and/or authentication measures which are not authorized;</li>
<li>restricting, disrupting or disabling service to users, hosts, servers, or networks;</li>
<li>illicitly reproducing TCP/IP packet header;</li>
<li>disrupting network services and otherwise disrupting Website and/or Services owner's ability to monitor the Website and/or Services;</li>
<li>using any robot, spider, or other automatic device, process, or means to access the Website and/or Services for any purpose, including monitoring or copying any of the material on the Website and/or Services;</li>
<li>introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
<li>attacking the Website and/or Services via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing, or crashing; and</li>
<li>otherwise attempting to interfere with the proper working of the Website and/or Services.</li>
</ol>

<p>The Services may contain e-mail services, bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, photo albums, file cabinets and/or other message or communication facilities designed to enable you to communicate with others (each a "Communication Service" and collectively "Communication Services"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and, when applicable, related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using the Communication Services, you will not:• Use the Communication Services in connection with surveys, contests, pyramid schemes, chain letters, junk email, spamming or any duplicative or unsolicited messages (commercial or otherwise).</p>
<ul>
<li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</li>
<li>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene, indecent, or unlawful topic, name, material, or information.</li>
<li>Upload, or otherwise make available, files that contain images, photographs, software, or other material protected by intellectual property laws, including, by way of example, and not as limitation, copyright, or trademark laws (or by rights of privacy or publicity) unless you own or control the rights thereto or have received all necessary consent to do the same.</li>
<li>Use any material or information, including images or photographs, which are made available through the Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.</li>
<li>Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property of another.</li>
<li>Advertise or offer to sell or buy any goods or services for any business purpose unless such Communication Services specifically allows such messages.</li>
<li>Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally reproduced, displayed, performed, and/or distributed in such manner.</li>
<li>Falsify or delete any copyright management information, such as author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.</li>
<li>Restrict or inhibit any other user from using and enjoying the Communication Services.</li>
<li>Violate any code of conduct or other guidelines which may be applicable for any particular Communication Service.</li>
<li>Harvest or otherwise collect information about others, including e-mail addresses.</li>
<li>Violate any applicable laws or regulations.</li>
<li>Create a false identity for the purpose of misleading others.</li>
<li>Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory of users of the Services or other user or usage information or any portion thereof.</li>
</ul>

<p>Nucleus has no obligation to monitor the Communication Services. However, Nucleus reserves the right to review materials posted to the Communication Services and to remove any materials in its sole discretion. Nucleus reserves the right to terminate your access to any or all of the Communication Services at any time, without notice, for any reason whatsoever.</p>
<p>
Nucleus reserves the right at all times to disclose any information as Nucleus deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Nucleus's sole discretion.</p>
<p>
Always use caution when giving out any personally identifiable information about yourself or your children in any Communication Services. Nucleus does not control or endorse the content, messages or information found in any Communication Services and, therefore, Nucleus specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Services. Managers and hosts are not authorized Nucleus spokespersons, and their views do not necessarily reflect those of Nucleus.</p>
<p>
Materials uploaded to the Communication Services may be subject to posted limitations on usage, reproduction and/or dissemination; you are responsible for adhering to such limitations if you download the materials.</p>

<h2><u>Member Account, Password, and Security</u></h2>
<p>
If any of the Website and/or Services requires you to open an account, you must complete the registration process by providing us with current, complete, and accurate information as prompted by the applicable registration form. You also will choose a password and a username. You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify Nucleus immediately of any unauthorized use of your account or any other breach of security. Nucleus will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by Nucleus or another party due to someone else using your account or password. You may not use anyone else's account at any time, without the permission of the account holder.</p>
<p>
We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of this TOU.</p>

<h2><u>Content</u></h2>
<p>
All content included in or made available through the Website and/or Services, such as text, graphics, logos, icons, images, sounds, music, digital downloads, data compilation, software, and documents, is the exclusive property of Nucleus or its content suppliers and is protected by the various applicable trade dress, copyright, trademark, patent, and other intellectual property and unfair competition laws in the United States and internationally (collectively, "Content"). All rights not expressly granted to you in this TOU are reserved and retained by Nucleus or its licensors, suppliers, publishers, rightsholders, or other content providers.</p>

<h2><u>Copyright Infringement Policy</u></h2>
<p>
It is Nucleus' policy to respond to any claim that content posted on the Website infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity. If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright Infringement, please submit your claim via email to <a href='mailto:info@nucleus.com'>info@nucleus.com</a>, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”.</p>
<p>
You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the Infringement of any content found on and/or through the Service on your copyright.</p>

<h2><u>DMCA Notice and Procedure</u></h2>
<p>
You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C § 512(c)(3) for further detail): (1) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest; (2) a description of the copyrighted work that you claim is the subject of Infringement, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work; (3) identification of the URL or other specific location on the Platform where the material that you claim is infringing is located; (4) your address, telephone number, and email address; (5) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (6) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.  You can contact us via email at <a href='mailto:info@nucleus.com'>info@nucleus.com</a>.</p>

<h2><u>Software</u></h2>
<p>
Any software that is made available to download from the Services ("Software") is the copyrighted work of Nucleus and/or its suppliers. Use of the Software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the Software ("License Agreement"). An end user will be unable to install any Software that is accompanied by or includes a License Agreement, unless he or she first agrees to the License Agreement terms. Third party scripts or code, linked to or referenced from this Website and/or Services, are licensed to you by the third parties that own such code, not by Nucleus.</p>
<p>
The Software is made available for download solely for use by end users according to the License Agreement. Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly prohibited by law and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.</p>
<p>
<b>Without limiting the foregoing, copying or reproduction of the software to any other server or location for further reproduction or redistribution is expressly prohibited, unless such reproduction or redistribution is expressly permitted by the license agreement accompanying such software.</b></p>
<p>
The software is warranted, if at all, only according to the terms of the license agreement. <b>Except as warranted in the license agreement, Nucleus hereby disclaims all warranties and conditions with regard to the software, including all warranties and conditions of merchantability, whether express, implied or statutory, fitness for a particular purpose, title and non-infringement.</b> For your convenience, Nucleus may make available as part of the services or in its software products, tools, and utilities for use and/or download. <b>Nucleus does not make any assurances with regard to the accuracy of the results or output that derives from such use of any such tools and utilities.</b> Please respect the intellectual property rights of others when using the tools and utilities made available on the Services or in Nucleus software products.</p>

<h2><u>No Unlawful or Prohibited Use</u></h2>
<p>
As a condition of your use of the Website and/or Services, you will not use the Website and/or Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Website and/or Services in any manner that could damage, disable, overburden, or impair any Nucleus server, or the network(s) connected to any Nucleus server, or interfere with any other party's use and enjoyment of the Website and/or any Services. You may not attempt to gain unauthorized access to the Website and/or any Services, other accounts, computer systems or networks connected to any Nucleus server or to the Website and/or any of the Services, through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Website and/or Services. You may not attempt to copy or change, alter, or otherwise attempt to modify the Website and/or Services or these TOU.</p>

<h2><u>
Monitoring and Enforcement, Suspension, and Termination</u></h2>
<p>
Nucleus has the right, without provision of notice to:</p>
<ul>
<li>Remove or refuse to post on the Website and/or Services any user submissions for any or no reason in our sole discretion.</li>
<li>At all times, take such actions with respect to any user submission deemed necessary or appropriate in our sole discretion, including, without limitation, for violating this TOU.</li>
<li>Take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Website and/or Services. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website and/or Services.</li>
<li>Terminate or suspend your access to all or part of the Website and/or Services for any or no reason, including, without limitation, any violation of this TOU.</li>
</ul>

<p>YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.</p>
<p>
We have no obligation, nor any responsibility to any party to monitor the Website and/or Services or its use, and do not and cannot undertake to review material that you or other users submit to the Website and/or Services. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party, subject to applicable laws.</p>

<h2><u>Links to Third-party Sites</u></h2>
<p>
For your convenience, this Website and/or Services may provide links or pointers to third-party sites. We make no representations about any other Websites and/or Services that may be accessed from this Website and/or Services. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites.  Nucleus is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Nucleus of the site.</p>
<p>
Our Website must not be framed on any other site, nor may you create a link to any part of our Website other than its homepage. We reserve the right to withdraw linking permission without notice. The Website in which you are linking must comply in all respects with this TOU and any user submission and site content standards that may be established by Nucleus from time to time. You agree to cooperate with us in causing any unauthorized framing or linking to immediately stop.</p>

<h2><u>Unsolicited Idea Submission Policy</u></h2>
<p>
Nucleus or any of its employees do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new products or technologies, processes, materials, marketing plans or new product names. Please do not send any original creative artwork, samples, demos, or other works. The sole purpose of this policy is to avoid potential misunderstandings or disputes when Nucleus’s products or marketing strategies might seem similar to ideas submitted to Nucleus. So, please do not send your unsolicited ideas to Nucleus or anyone at Nucleus. If, despite our request that you not send us your ideas and materials, you still send them, please understand that Nucleus makes no assurances that your ideas and materials will be treated as confidential or proprietary.</p>

<h2><u>Geographic Restrictions</u></h2>
<p>
The owner of the Website and/or Services is based in the United States. We provide this Website and/or Services for use only by persons located in the United States and Canada. This Website and/or Services is not intended for use in any jurisdiction where its use is not permitted. If you access the Website and/or Services from outside the United States or Canada, you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.</p>

<h2><u>Disclaimer of Warranties</u></h2>
<p>
YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE AND/OR SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE AND/OR SERVICES IS AT YOUR OWN RISK. THE WEBSITE AND/OR SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE AND/OR SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
<p>
NEITHER NUCLEUS NOR ITS PARENT, SUBSIDIARIES/AFFILIATES (INCLUDING, BUT NOT LIMITED TO, FIBERNETICS CORPORATION, NEWT, FONGO INC., AND EYESURF CORP.), OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE AND/OR SERVICES OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING,  NEITHER NUCLEUS NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR  RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,  CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS  REPRESENT OR WARRANT THAT THE WEBSITE AND/OR SERVICES, ITS CONTENT,  OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE AND/OR SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR  UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE AND/OR SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF  VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
<p>
WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET, THE WEBSITE AND/OR SERVICES WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND/OR SERVICES AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE AND/OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE AND/OR SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE AND/OR SERVICES LINKED TO IT.</p>

<h2><u>Limitation on Liability</u></h2>
<p>
EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL NUCLEUS NOR ITS PARENT, SUBSIDIARIES, AFFILIATES(INCLUDING, BUT NOT LIMITED TO, FIBERNETICS CORPORATION, NEWT, FONGO INC. AND EYESURF CORP.) OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE AND/OR SERVICES, ANY LINKED WEBSITES AND/OR SERVICES, OR SUCH OTHER THIRD-PARTY WEBSITESAND/OR SERVICES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON.</p>

<h2><u>Indemnification</u></h2>
<p>
To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless Nucleus, its parent, subsidiaries, affiliates (including, but not limited to, Fibernetics Corporation, NEWT, Fongo Inc. and Eyesurf Corp.), and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal fees) arising out of or relating to your breach of this TOU or your use of the Website and/or Services, including, but not limited to, your user submissions, third-party sites, any use of the Website's and/or Services content, its services, and products other than as expressly authorized in this TOU.</p>

<h2><u>Governing Law and Choice of Forum</u></h2>
<p>
This TOU will be governed by and construed in accordance with the laws of the State of North Carolina and the federal laws of the United States, without giving effect to any choice or conflict of law provision, principle, or rule and notwithstanding your domicile, residence, or physical location.</p>
<p>
Any action or proceeding arising out of or relating to this Website and/or Services and under this TOU will be instituted in the state or federal courts located in the State of Carolina, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</p>

<h2><u>Waiver</u></h2>
<p>
No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this TOU operates, or may be construed, as a waiver thereof; and no single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</p>

<h2><u>Severability</u></h2>
<p>
If any term or provision of this TOU is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this TOU or invalidate or render unenforceable such term or provision in any other jurisdiction.</p>

<h2><u>Entire Agreement</u></h2>
<p>
This TOU and our Privacy Policy constitute the sole and entire agreement between you and Nucleus regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter.</p>

<h2><u>Reporting and Contact</u></h2>
<p>
This Website and/or Services is operated by Nucleus.</p>
<p>
Should you become aware of misuse of the Website including libelous or defamatory conduct, you must report it to the Company at <a href='mailto:info@nucleus.com'>info@nucleus.com</a>.</p>
<p>
All other feedback, comments, requests for technical support, and other communications relating to the Website/or Services should be directed to <a href='mailto:info@nucleus.com'>info@nucleus.com</a>.</p>

<hr />

<h1>APPENDIX A</h1>


<h2><u>Definition and Description of Services</u></h2>
<p>
Nucleus Technologies Inc. provides a cloud-based phone system which comes fully equipped with a new Canadian or US Phone Number for your business as well as an AI attendant with the ability to:</p>
<ul>
<li>Answer the phone with your company name</li>
<li>Lookup users in a company directory</li>
<li>Transfer calls to any team member in the United States or Canada (<i>Some limitations apply</i>)</li>
<li>Send notifications via SMS or Email</li>
<li>Unlimited usage (<i>based on fair usage policy</i>)</li>
<li>Set your teams hours to take live calls</li>
</ul>


<p>Nucleus Technologies Inc. also provides a Premium paid option which comes fully equipped with the above services and additional capabilities to:</p>
<ul>
<li>Communicate a custom greeting</li>
<li>Communicate a custom end call message</li>
<li>Answer questions based on your business</li>
<li>Port an existing business number</li>
<li>More advanced voice models</li>
<li>Add knowledge cards to expand your digital employees’ abilities</li>
</ul>

            </Box>            
            </Stack>
        </Grid>
      </Box>
    );
  }
  
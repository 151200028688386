import { Box, Grid, Stack, Typography } from "@mui/material";

export default function ComplaintView() {
  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} direction="column">
        <Box
          sx={{
            backgroundColor: "#0a132382",
            backgroundImage: `url(https://www.newt.ca/wp-content/uploads/2020/06/main-working-space2-scaled-e1592253041130.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
            maxHeight: "300px",
          }}
        >
          <Box className="wrapper">
            <Box className="block-left">
              <Typography variant="h2" sx={{ color: "white" }}>
                {" "}
                How to make a complaint
              </Typography>
            </Box>
          </Box>
        </Box>

        <Stack component="section" direction="column" padding={2}>
          <Box
            component="img"
            sx={{ margin: "auto" }}
            src="https://www.newt.ca/wp-content/uploads/2022/03/CCTS_Vert_BIL_EN_First-400x326.webp"
            alt="CCTS_Vert_BIL_EN_First-300x244 NEWT Complaints"
          />
        </Stack>
        <Stack component="section" direction="column" padding={4}>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            <Grid item xs={12} sm={4}>
              <article>
                <h2>Submit A Written Complaint</h2>
                <Typography>
                  If you have a complaint, please send a formal written
                  complaint to{" "}
                  <a
                    href="mailto:customercomplaints@nucleus.com"
                    target="_top"
                    rel="noopener noreferrer"
                  >
                    <u>customercomplaints@nucleus.com</u>
                  </a>
                  .
                </Typography>
                <Typography>
                  We will investigate further into your issue and will normally
                  provide a response within 2 business days.
                </Typography>
              </article>
            </Grid>

            <Grid item xs={12} sm={8}>
              <article>
                <h2>
                  Commission For Complaints For Telecom-television Services
                  (CCTS)
                </h2>
                <Typography>
                  Commission for Complaints for Telecom-television Services
                  (CCTS) CCTS is an independent agency whose mandate is to
                  resolve complaints of consumers about their telecom and TV
                  services, and complaints of small business customers about
                  their telecom services, free of charge.{" "}
                </Typography>

                <Typography>
                  If you have a complaint about your telephone, wireless,
                  internet or TV service, you must first try to resolve it
                  directly with your service provider. If you have done so and
                  have been unable to reach a satisfactory resolution, CCTS may
                  be able to help you.{" "}
                </Typography>

                <Typography>
                  To learn more about CCTS, you may visit its website at{" "}
                  <a
                    href="https://www.ccts-cprst.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>www.ccts-cprst.ca</u>
                  </a>{" "}
                  or call toll-free at 1-888-221-1687.
                </Typography>
              </article>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Box>
  );
}

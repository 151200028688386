import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const showFriendlyErrorFromHTMLCode = (
  error: any,
  default_value: string = "An error occured. Please try again."
): string | null => {
  if(error)
    return showFriendlyErrorFromData(error.response?.data, default_value);
  return null
};

export const showFriendlyErrorFromData = (
  data: any,
  default_value: string = "An error occured. Please try again."
): string => {
  if (data?.detail?.length > 0) {
    if (data?.detail[0].msg) return data?.detail[0].msg;
  }
  if (data?.detail) return data?.detail;
  return default_value;
};

export function useMediaMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
}

export function useReferer() {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("ref");
  if (ref) return ref;
}

export function titleCase(s?: string) {
  if(s)
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  return ""
}


/** Navigate to the top of a page so that the scroll position isn't persisted between pages. Use this instead of React Dom's build-in @see {@link useNavigate}. */
export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateAndReset = (to: string) => {
    window.scrollTo(0, 0);
    navigate(to, { replace: true });
    
  };

  return navigateAndReset;
};

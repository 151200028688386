import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';


const TrackingContext = React.createContext<{
  actions: {};
  data: {};
}>({
  actions: {},
  data: {},
});

interface TrackingContextProviderProps {
  children: React.ReactNode;
}

export function TrackingContextProvider(
  props: TrackingContextProviderProps
): React.ReactElement {
  const { children } = props;

  let location = useLocation();
  useEffect(() => {
    const TRACKING_ID = process.env.REACT_APP_GA_MEASURE_ID as string;
    if(TRACKING_ID)
      ReactGA.initialize(TRACKING_ID);

    const gtmId = process.env.REACT_APP_GTM_ID as string;
    if (gtmId) {
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <TrackingContext.Provider
      value={{
        actions: {},
        data: {},
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}
export default TrackingContext;

import { Grid, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useMediaMobile } from "../utils/utils";
export default function FAQsView() {
  const isMobile = useMediaMobile();
  return (
    <Grid container sx={{ background: "white" }}>
      {!isMobile && (
        <Grid item xs={0} sm={4}>
          <Grid container margin={0} direction="column">
            <Stack
              component="section"
              direction="column"
              padding={2}
              sx={{ marginTop: 4 }}
            >
              <Typography>
                <Typography variant="h4">
                  <RouterLink to="#creation" color="inherit">
                    Account Creation and Cancelling
                  </RouterLink>
                </Typography>
                <Typography variant="h4">
                  <RouterLink to="#accounts" color="inherit">
                    Starter and Premium Accounts
                  </RouterLink>
                </Typography>
                <Typography variant="h4">
                  <RouterLink to="#transfer" color="inherit">
                    Transferring Calls, Receiving Messages, and Company
                    Directory
                  </RouterLink>
                </Typography>
                <Typography variant="h4">
                  <RouterLink to="#teach" color="inherit">
                    Teach Alice
                  </RouterLink>
                </Typography>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={8}>
        <Grid container margin={0} direction="column">
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h2">FAQs</Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography id="creation" variant="h1">
              Account Creation and Cancelling
            </Typography>
            <Typography variant="h3">
              How do I sign up (create a Starter Account) and get Alice for my
              business?
            </Typography>
            <Typography variant="body1">
              To create your <b>free Starter Account</b> and get Alice, working
              for you:
              <ul>
                <li>
                  Go to <a href="/">nucleus.com</a> and click{" "}
                  <b>Activate Alice</b>
                </li>
                <li>Enter your Email Address</li>
                <li>
                  Select your Phone Region from the drop-down (you can change
                  this later)
                </li>
                <li>
                  Select your Attendant’s Voice (you can change this later)
                </li>
                <li> Enter your Business Name (you can change this later)</li>
                <li>
                  List your team (you need to at least add yourself as a team
                  member; you can add more members later)
                </li>
                <li> Enter your first name</li>
                <li> Enter your last name</li>
                <li>
                  Enter the phone number you want Alice to transfer your calls
                  to
                </li>
                <li>
                  {" "}
                  Click <b>Create My Account</b>
                </li>
                <li>
                  You’re now in the Nucleus portal. Your new phone number is
                  listed at the top of the page. You can call this number right
                  now to hear Alice answer.
                </li>
                <li>
                  To fully activate your Starter Account, check your email and
                  click the link to activate
                </li>
              </ul>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How do I create or upgrade to a Premium account?
            </Typography>
            <Typography variant="body1">
              To create or upgrade to a Premium account, you must first have a
              Starter Account
              <ul>
                <li>
                  Create your Starter Account or login to your Starter Account
                  if you already have one. (Any settings you’ve created for your
                  Starter Account will remain intact when you upgrade to
                  Premium.)
                </li>
                <li>
                  In your Nucleus portal, click the appropriate link under the
                  “Greetings” or “Instruction Builder” dropdown:
                  <img src="/images/faq/faq1.png" />
                </li>
                <li>
                  You will be directed to the payment page where you can choose
                  to pay monthly or annually:
                  <img src="/images/faq/faq2.png" />
                </li>
                <li>
                  Click the <b>Toggle</b> (circled above) to bill annually and
                  save US$40{" "}
                </li>
                <li>
                  Click <b>Start trial</b>
                </li>
              </ul>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">How do I login to my account?</Typography>
            <Typography variant="body1">
              Go to <a href="/">Nucleus.com</a> and click Sign In. Use the email
              and password you set when you signed up for your Nucleus account.
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">How do I cancel my account?</Typography>
            <Typography variant="body1">
              If you wish to cancel it your Starter or Premium account:
              <ul>
                <li> Login to your Nucleus account</li>
                <li>
                  Click on your Account Avatar at the top right-corner of the
                  portal
                </li>
                <li> Select Settings</li>
                <li> Select Close Account</li>
              </ul>
              Warning: closing your account will immediately cancel your account
              forfeiting any remaining time on your Premium subscription
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">How do I downgrade my account?</Typography>
            <Typography variant="body1">
              If you wish to downgrade your account from Premium to Starter
              (free):
              <ul>
                <li>Login to your Nucleus account</li>
                <li>
                  Click on your Account Avatar at the top right corner of the
                  portal
                </li>
                <li>Select Settings</li>
                <li>Select Billing Portal</li>
                <li>Select Cancel Subscription</li>
                <li>
                  Confirm Cancelation will end at the end of the current billing
                  period
                </li>
              </ul>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography id="accounts" variant="h1">
              Starter and Premium Accounts
            </Typography>
            <Typography variant="h3">
              What’s included in each account?
            </Typography>
            <Typography variant="body1">
              In the free <b>Starter</b> Account, you can:
              <ul>
                <li>
                  Change your business name (this is the name Alice will say as
                  part of the standard Welcome Greeting when she answers your
                  phone)
                </li>
                <li>Select your time zone</li>
                <li>Select from two different receptionist voices</li>
                <li>
                  Add 10 number of Team Members to your Company Directory and
                  tell Alice when they are Available to receive calls, and how
                  she should send messages about missed calls (see more
                  information under Transferring Calls and Company Directory
                  below)
                  <img src="/images/faq/faq3.png" />
                </li>
              </ul>
            </Typography>
            <Typography variant="body1">
              In the <b>Premium</b> account you get all Starter features plus
              you can:
              <li>Select from more Attendant voices</li>
              <li>Input customized Greetings (a Welcome and Goodbye)</li>
              <li>
                Teach Alice about your business so she can answer caller’s
                specific questions (see more details below in the Teach Alice
                section)
              </li>
              <li>
                Bring your existing business phone number to the Nucleus
                solution and have Alice answer your existing business phone
                number
              </li>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How do I bring my existing business number to Nucleus once I
              upgrade to Premium?
            </Typography>
            <Typography variant="body1">
              If you want Alice to answer your pre-existing business phone
              number, upgrade to the Premium account and email us at
              <a href="mailto://support@nucleus.com">
                support@nucleus.com
              </a>{" "}
              with the request to port your existing phone number. We will need
              to know your current phone number and current carrier.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              Will you be adding more voices?
            </Typography>
            <Typography variant="body1">
              Yes, we’re working with premium voice providers to increase the
              available voice offerings and bring even more realistic sounding
              AI Attendants.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How many team members can I add?
            </Typography>
            <Typography variant="body1">
              We currently allow an 10 number of team members.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              What do the “Greetings” settings mean and how can I customize how
              callers are greeted?
            </Typography>
            <Typography variant="body1">
              <b>Welcome:</b> In the Starter plan, callers receive a standard
              greeting when they dial your business phone number. This greeting
              includes your company name as it appears in the “Business Name”
              field of the portal (this is the name you entered for your
              business when you signed up).
            </Typography>
            <Typography variant="body1">
              <b>Goodbye:</b> The Goodbye message is spoken to callers right
              before Alice intends to hang up the call. This Goodbye is made on
              a “best effort” basis and is determined by Alice’s ability to
              detect the caller’s intention to end the call.
            </Typography>
            <Typography variant="body1">
              <b>Customized greetings and farewells</b> are available to Premium
              users. If you are a Premium user:
              <ul>
                <li>Login to your Nucleus portal</li>
                <li>Type your customized greeting in the Welcome field</li>
                <li>Type your customized farewell in the Goodbye field</li>
              </ul>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography id="transfer" variant="h1">
              Transferring Calls, Receiving Messages, and Company Directory
            </Typography>
            <Typography variant="h3">
              How do I add Team Members to the Company Directory so Alice will
              transfer calls to them or take a message?
            </Typography>
            <Typography variant="body1">
              Alice can transfer calls to as many team members as you want. All
              you need to do is enter each Team Member into your Company
              Directory:
              <ul>
                <li>Login to your Nucleus portal</li>
                <li> Under Team Members, click Add More Staff</li>
                <li>
                  {" "}
                  Add a Team Member’s profile by inputting their:
                  <ul>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>
                      Phone number (this is the phone number Alice will transfer
                      calls to, and send SMS (text) messages to regarding missed
                      calls)
                    </li>
                    <li>
                      Email address (if they want to receive email notifications
                      of messages)
                    </li>
                    <li>
                      Select their Availability to receive calls (see
                      Availability settings below for more info on how to set a
                      Team Member’s Availability and office hours)
                    </li>
                  </ul>
                </li>
                <li>
                  Select whether this team member wants to be notified of missed
                  calls by Email or SMS (text)
                </li>
                <li>
                  Click <b>Save Changes</b>
                </li>
              </ul>
            </Typography>
            <Typography variant="body1">
              <b>Company Directory:</b> Once you have added a Team Member, they
              are automatically added to your Company Directory. Any caller
              asking to speak to that Team Member will be transferred to the
              Team Member at the phone number entered in their Team Member
              profile during their Available hours (see Availability settings
              below). Alice will take a message for any calls that come in when
              the Team Member is Unavailable and will send them the message via
              Email or SMS (text) depending on what has been selected in their
              Team Member profile.
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How do I remove a Team Member from the Company Directory?
            </Typography>
            <Typography variant="body1">
              <ul>
                <li> Login to your Nucleus portal</li>
                <li>
                  Under Team Members, click on the name of the Team Member you
                  wish to remove to open their Team Member profile
                </li>
                <li>
                  Click <b>Remove Team Member</b>
                </li>
              </ul>
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              Can I be notified of missed calls by both Email and SMS?
            </Typography>
            <Typography variant="body1">
              No, currently you must choose to receive notifications either by
              Email or SMS.
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              What do the “Availability” settings in the Team Member profiles
              mean?
            </Typography>
            <Typography variant="body1">
              When adding a Team Member to the Company Directory, you set their
              Availability to tell Alice when to transfer calls to them, and
              when to take a message instead:
              <ul>
                <li>Login to your Nucleus portal</li>
                <li>
                  Click on Team Members and select a Team Member to set their
                  Availability
                </li>
                <li>
                  In the Availability drop down menu, select from:
                  <ul>
                    <li>
                      <b>Available. Send calls through:</b> Alice will{" "}
                      <b>always</b> route calls to this Team Member, regardless
                      of the day or time of day.
                    </li>
                    <li>
                      <b>Unavailable. Take a message:</b> Alice will{" "}
                      <b>always</b> tell the caller that this Team Member is
                      Unavailable and will offer to take a message. Alice will
                      then deliver the caller’s message via Email or SMS (text)
                      to the Team Member, depending on what delivery mechanism
                      has been set in their Team Member profile.
                    </li>
                    <li>
                      <b>Schedule. Accept calls based on timetable below:</b>{" "}
                      Alice will only send calls to the Team Member during the
                      hours you specify in the Schedule widget. Alice will take
                      a message and deliver the message for any calls outside of
                      those hours.
                    </li>
                  </ul>
                </li>
                <li>
                  To set Availability hours (office hours) in the Schedule
                  widget:
                  <ul>
                    <li>
                      Select “
                      <b>Schedule. Accept calls based on timetable below.</b>”
                      for the Team Member’s Availability
                    </li>
                    <li> The Schedule widget will appear</li>
                    <li>
                      Uncheck any days of the week that the Team Member is
                      Unavailable. Alice will take messages and forward them via
                      Email or SMS (text) during these days.
                    </li>
                    <li>
                      Check any days of the week that the Team Member is
                      Available to take calls and select their Available hours
                      for each checked day by clicking on the Clock icon and
                      selecting the Team Member’s Available‘From’ and ‘To’
                      hours. Alice will forward calls to the Team Member during
                      those hours and will take a message any other time.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
            <Typography variant="body1">
              <b>Please note:</b> You cannot currently set a time zone for each
              Team Member; a time zone can only be set for the entire system.
              Therefore, if you have a Team Member who works in a different time
              zone, you must set their Availability hours according to the time
              zone you selected for your business.
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              Why am I blocked from transferring calls to my number?
            </Typography>
            <Typography variant="body1">
              While most destinations in the US & Canada are included, certain
              high-cost destinations are blocked. This includes any
              international numbers (non-US or non-Canadian).
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              Can I transfer to international numbers?
            </Typography>
            <Typography variant="body1">
              No, only certain North American number destinations are included.
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              A caller left me a message, but I didn’t get an Email
              notification.
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>Login to your Nucleus portal</li>
                <li>
                  In the Team Members section, select your name to open your
                  Team Member profile
                </li>
                <li>
                  Verify that your message notifications are set to Email and
                  not SMS (text)
                </li>
                <li>
                  Verify that there are no typos in the Email address field
                </li>
                <li>
                  If you Email is correct, check your spam/junk folder. It may
                  also be beneficial to add donotreply@nucleus.com to your safe
                  list or contacts
                </li>
                <li>
                  If you are still having problems, please Email
                  support@nucleus.com
                </li>
              </ul>
            </Typography>
          </Stack>
          <Stack component="section" direction="column" padding={2}>
            <Typography id="teach" variant="h1">
              Teach Alice
            </Typography>
            <Typography variant="body1">
              In the <b>Instruction Builder</b> of your Nucleus portal, you can
              add knowledge cards to teach Alice about your business and tell
              her how to interact with callers and answer their questions.
              Knowledge cards are only available for Premium users.
              <img src="/images/faq/faq4.png" />
              Above: Example knowledge card with Title and Body.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How do I add and remove knowledge cards?
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>Login to your Nucleus portal</li>
                <li>
                  Click on <b>Instruction Builder</b>
                </li>
                <li>
                  Click on Add Card to add a new knowledge card and add a Title
                  and Body instructions for Alice (see Title and Body question
                  below for more details)
                </li>
                <li>
                  ClickRemove on any individual card to remove that knowledge
                  card
                </li>
                <li>Click Submit to update your model’s instructions</li>
                <li>
                  You will see a “Success” message in the banner at the top of
                  the portal page indicating Alice’s knowledge cards have
                  successfully updated
                </li>
              </ul>
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              What do I put in the Title of each knowledge card, versus the
              Body?
            </Typography>
            <Typography variant="body1">
              <b>Title:</b> The Title is for your internal use to help you
              organize your cards. It doesn’t affect Alice's instructions or
              behavior.
            </Typography>
            <Typography variant="body1">
              <b>Title Examples:</b> “Frequently Asked Questions” or “Services”
            </Typography>
            <Typography variant="body1">
              <b>Body:</b> The body is where you instruct Alice how to respond
              to callers based on what they say. Alice can hold a lot of
              information in one card, so you don’t need to create a card for
              each question.
            </Typography>
            <Typography variant="body1">
              <b>Body Example 1:</b>
              <br />
              <em>
                If a caller asks any of the following questions designated by
                "Q:" provide the proceeding answer designated by "A:". Do not
                say "A:", rather only the answer that follows:
              </em>
            </Typography>
            <Typography variant="body1">
              Then, list your FAQs and their answers, putting “Q:” and “A:” in
              front of each question and answer.
            </Typography>
            <Typography variant="body1">
              <b>Body Example 2:</b>
              <br />
              <em>
                If a caller asks about any of our Services, advise the
                following: We have a variety of services including tire
                rotations, engine checks, and internal and external vehicle
                cleaning. On Wednesday we have a drop-in service to bring your
                car for headlight checks. Would you like to hear our pricing for
                any of those services or talk to someone to schedule an
                appointment?
              </em>
            </Typography>
            <Typography variant="body1">
              When you’re done inputting knowledge cards, be sure to click
              Submit to save your changes. Then, test your system by calling
              Alice, asking her questions, and adjusting your knowledge cards as
              needed. How many custom knowledge cards can I add to teach Alice
              about my business? We currently support up to 10 custom knowledge
              cards, but each knowledge card can hold multiple questions, for
              example, a list of FAQs.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              How do I designate roles for certain team members such as Sales,
              Billing, or Bookings?
            </Typography>
            <Typography variant="body1">
              You can specify these in the <b>Instruction Builder.</b> Below is
              an example:
            </Typography>
            <Typography variant="body1">
              <b>Card Title:</b> Teams
            </Typography>
            <Typography variant="body1">
              <b>Card Body:</b>
            </Typography>
            <Typography variant="body1">
              <em>
                If the caller asks about Sales, transfer them to John Smith.
              </em>
            </Typography>
            <Typography variant="body1">
              <em>
                If the caller asks about Billing, transfer them to Mary
                Davidson.
              </em>
            </Typography>
            <Typography variant="body1">
              <em>
                If the caller asks about Bookings, transfer them to Tony Simpson
              </em>
            </Typography>
            <Typography variant="body1">
              If a person handles multiple areas in your company, you may choose
              to write something like this:
            </Typography>
            <Typography variant="body1">
              <em>
                If the caller asks about any of the following items, then
                transfer them to John: Donations, Organizational mission,
                finance.
              </em>
            </Typography>
            <Typography variant="body1">
              If the caller asks about any of the following items, transfer them
              to Mary: Events, Volunteering, Meals.
            </Typography>
            <Typography variant="body1">
              If the caller asks about any of the following items, transfer them
              to Tony: Lodging, Bookings, Reservations, Day Camp
            </Typography>
            <Typography variant="body1">
              These are just suggestions, and ultimately you are free to
              experiment and see what works best for you. We recommend that you
              set up your knowledge cards and thencall your business phone
              number and talk to Alice, test her knowledge, and adjust the cards
              as necessary.
            </Typography>
          </Stack>

          <Stack component="section" direction="column" padding={2}>
            <Typography variant="h3">
              I want to provide product info to my callers. How do you recommend
              I set this up?
            </Typography>
            <Typography variant="body1">
              You can add a custom card with your product and info, and even
              pricing if you want. Here is an example for a fictional furniture
              company:{" "}
            </Typography>
            <Typography variant="body1">
              <b>Card Title:</b> Product Info
            </Typography>
            <Typography variant="body1">
              <b>Card Body:</b>
            </Typography>
            <Typography variant="body1">
              If a caller asks about our products, advise them of the following:{" "}
            </Typography>
            <Typography variant="body1">
              At Everwood Furniture, we take pride in crafting high-quality
              desks designed to meet the needs of our customers. Our product
              lines are carefully curated to ensure a balance of aesthetics,
              functionality, and durability. Below are our key desk product
              lines and what makes each unique.{" "}
            </Typography>
            <Typography variant="body1">
              Everwood Classic Series: Made from 100% oak wood, traditional
              design, features spacious surface and cable management, best for
              home offices and executives, superior for its durability and
              timeless look.{" "}
            </Typography>
            <Typography variant="body1">
              Everwood Modern Series: Made from walnut wood with steel accents,
              sleek and minimalist design, features hidden storage, best for
              modern workplaces, superior for space efficiency and contemporary
              style.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { Button, ButtonProps, CSSObject, styled } from "@mui/material";

export const NucleusButton = styled(Button)<ButtonProps>(({ theme, variant }) => {

  const overrides: CSSObject = {};


    overrides.color = theme.palette.getContrastText(theme.palette.primary.main);
    overrides.borderColor = theme.palette.primary.main;
    overrides.border = '1px solid';
    overrides.backgroundColor = theme.palette.primary.main;
    overrides['&:hover'] = {
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    };
    overrides.borderRadius= '1.5rem';
    overrides.lineHeight = '1.4rem';
    overrides.fontSize = '1rem';
    overrides.fontWeight = 400;
    overrides.padding = '.8rem 1.5rem';
    overrides.boxShadow = 'none';

    if (variant === 'outlined') {
      overrides.color = theme.palette.primary.main;
      overrides.border = '1px solid';
      overrides.borderColor = theme.palette.primary.main;
      overrides.backgroundColor =  theme.palette.background.default;
      overrides['&:hover'] = {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      };      
    }

    return {
      ...overrides,
    };

});
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
export default function ContactView() {
  const theme = useTheme();
  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} direction="column">
        <Box
          sx={{
            backgroundColor: "#0a132382",
            backgroundImage: `url(https://www.newt.ca/wp-content/uploads/2020/06/main-working-space2-scaled-e1592253041130.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
          }}
        >
          <Box className="wrapper">
            <Box className="block-left">
              <Typography variant="h2" sx={{ color: "white" }}>
                Located within Canada’s Technology Triangle
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack component="section" direction="column" padding={4}>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              title="map"
              width="100%"
              height="400px"
              style={{ maxWidth: "1280px" }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBKAHCRKMv3F1cjbNoNA0lNUh9_uZr9reU&amp;language=en&amp;q=200+Bathurst+Dr,+Waterloo,+ON+N2V+2L7&amp;maptype=roadmap&amp;zoom=14"
            ></iframe>
          </Box>
        </Stack>

        <Stack
          component="section"
          direction="column"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <ReportIcon
            sx={{
              scale: "2",
              margin: 1,
              color: theme.palette.primary.main,
            }}
          />
          <Typography variant="h4">Complaints</Typography>
          <Typography variant="body1">
            <b>How to make a Complaint</b>
          </Typography>
          <Typography variant="body1">
            This page contains more detail about{" "}
            <a href="/contact-us/how-to-make-a-complaint">
              <u>how to submit your complaint.</u>
            </a>
          </Typography>
        </Stack>
      </Grid>
    </Box>
  );
}

import { Crisp } from "crisp-sdk-web";
import { ReactElement, useEffect } from "react";
export default function CrispChat(): ReactElement {
  const loadCrisp = async () => {
    const crispId: string | undefined = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispId) {
      Crisp.configure(crispId);
    }
  };

  useEffect(() => {
    loadCrisp();
  }, []);

  return <></>;
}

import React from 'react';

export const NewtCloudLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 130 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8256 27.7987V18.8507C22.8256 17.7599 22.6667 16.9394 22.349 16.3892C22.0408 15.8294 21.5402 15.5495 20.8469 15.5495C19.8937 15.5495 19.2052 15.9307 18.7815 16.6933C18.3579 17.4559 18.1461 18.7638 18.1461 20.6171V27.7987H12.4988V11.394H16.7595L17.4672 13.421H17.7994C18.2809 12.6488 18.9452 12.0697 19.7926 11.6836C20.6495 11.2975 21.6316 11.1044 22.7389 11.1044C24.5491 11.1044 25.9549 11.6305 26.9563 12.6826C27.9673 13.7348 28.4728 15.2164 28.4728 17.1277V27.7987H22.8256Z"
      fill="#333333"
    />
    <path
      d="M43.9701 27.7987L43.2624 25.7571H42.9302C42.4584 26.51 41.7989 27.0892 40.9515 27.4946C40.1138 27.8904 39.1269 28.0883 37.9907 28.0883C36.1902 28.0883 34.7892 27.567 33.7878 26.5245C32.7864 25.4724 32.2857 23.9907 32.2857 22.0795V11.394H37.933V20.3565C37.933 21.4279 38.0822 22.2387 38.3807 22.7889C38.6888 23.3392 39.1895 23.6143 39.8828 23.6143C40.8553 23.6143 41.5485 23.233 41.9626 22.4704C42.3766 21.6982 42.5836 20.3999 42.5836 18.5756V11.394H48.2308V27.7987H43.9701Z"
      fill="#333333"
    />
    <path
      d="M59.583 28.0883C56.9062 28.0883 54.8746 27.3643 53.4881 25.9164C52.1112 24.4685 51.4227 22.3884 51.4227 19.676C51.4227 16.9539 52.1689 14.8448 53.6614 13.3487C55.1538 11.8525 57.2769 11.1044 60.0307 11.1044C61.9276 11.1044 63.7137 11.5195 65.3891 12.3496L63.7281 16.534C63.0445 16.2348 62.4138 15.9983 61.8361 15.8246C61.2584 15.6412 60.6566 15.5495 60.0307 15.5495C59.116 15.5495 58.4083 15.9066 57.9076 16.6209C57.4069 17.3352 57.1566 18.3439 57.1566 19.647C57.1566 22.2725 58.1243 23.5853 60.0596 23.5853C61.7158 23.5853 63.3045 23.1027 64.8258 22.1374V26.6404C63.3719 27.6056 61.6243 28.0883 59.583 28.0883Z"
      fill="#333333"
    />
    <path
      d="M73.7227 27.7987H68.0755V5.26938H73.7227V27.7987Z"
      fill="#333333"
    />
    <path
      d="M85.5371 28.0883C82.8122 28.0883 80.6939 27.3643 79.1822 25.9164C77.6801 24.4589 76.929 22.3884 76.929 19.7049C76.929 16.9346 77.6271 14.811 79.0233 13.3342C80.4194 11.8477 82.4126 11.1044 85.0027 11.1044C87.4676 11.1044 89.3693 11.7511 90.7077 13.0446C92.0557 14.3284 92.7297 16.1817 92.7297 18.6045V21.1239H82.634C82.6725 22.0409 83.0047 22.76 83.6306 23.2812C84.2661 23.8025 85.1279 24.0631 86.2159 24.0631C87.2076 24.0631 88.1176 23.9714 88.9456 23.788C89.7833 23.5949 90.6884 23.2716 91.6609 22.8179V26.8865C90.7751 27.3402 89.8603 27.6539 88.9167 27.8276C87.9731 28.0014 86.8466 28.0883 85.5371 28.0883ZM85.2049 14.9558C84.5405 14.9558 83.982 15.1682 83.5295 15.5929C83.0866 16.008 82.8266 16.6643 82.7496 17.562H87.6024C87.5832 16.7705 87.3569 16.1383 86.9236 15.6653C86.4999 15.1923 85.927 14.9558 85.2049 14.9558Z"
      fill="#333333"
    />
    <path
      d="M107.577 27.7987L106.869 25.7571H106.537C106.065 26.51 105.406 27.0892 104.558 27.4946C103.721 27.8904 102.734 28.0883 101.598 28.0883C99.7971 28.0883 98.3961 27.567 97.3948 26.5245C96.3934 25.4724 95.8927 23.9907 95.8927 22.0795V11.394H101.54V20.3565C101.54 21.4279 101.689 22.2387 101.988 22.7889C102.296 23.3392 102.796 23.6143 103.49 23.6143C104.462 23.6143 105.155 23.233 105.57 22.4704C105.984 21.6982 106.191 20.3999 106.191 18.5756V11.394H111.838V27.7987H107.577Z"
      fill="#333333"
    />
    <path
      d="M128.375 22.76C128.375 24.5168 127.778 25.844 126.584 26.7417C125.39 27.6394 123.676 28.0883 121.442 28.0883C120.229 28.0883 119.175 28.0207 118.279 27.8856C117.384 27.7601 116.469 27.5236 115.535 27.1761V22.6442C116.411 23.0303 117.369 23.3488 118.409 23.5998C119.459 23.8507 120.393 23.9762 121.211 23.9762C122.434 23.9762 123.046 23.6963 123.046 23.1364C123.046 22.8469 122.872 22.5911 122.526 22.3691C122.189 22.1374 121.197 21.6934 119.55 21.037C118.048 20.4192 116.999 19.7194 116.402 18.9375C115.805 18.146 115.506 17.147 115.506 15.9404C115.506 14.4153 116.094 13.228 117.268 12.3786C118.443 11.5291 120.104 11.1044 122.251 11.1044C123.33 11.1044 124.341 11.2251 125.284 11.4664C126.237 11.698 127.224 12.0407 128.245 12.4944L126.714 16.1431C125.963 15.8053 125.169 15.5205 124.331 15.2888C123.493 15.0475 122.81 14.9269 122.28 14.9269C121.356 14.9269 120.894 15.1537 120.894 15.6074C120.894 15.8873 121.052 16.1286 121.37 16.3313C121.698 16.5244 122.631 16.925 124.172 17.5331C125.318 18.0061 126.17 18.4694 126.728 18.9231C127.297 19.3767 127.711 19.9125 127.971 20.5302C128.24 21.1383 128.375 21.8816 128.375 22.76Z"
      fill="#333333"
    />
    <ellipse
      cx="4.27625"
      cy="7.99165"
      rx="4.27625"
      ry="4.2869"
      fill="#5E6CB2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2717 35.9026C23.6448 35.5754 25.9124 34.7512 27.9314 33.4942C28.3784 33.216 28.8131 32.9166 29.2341 32.5966C29.2606 32.5765 29.2877 32.5578 29.3155 32.5406C29.7353 32.2797 30.2928 32.3444 30.6182 32.7272C30.9638 33.1339 30.9165 33.7459 30.4935 34.0714C30.4643 34.0938 30.4351 34.1162 30.4058 34.1384C29.9527 34.483 29.4853 34.8062 29.0051 35.1073C26.7437 36.5257 24.1997 37.4552 21.5364 37.8226L21.5351 37.8228C17.9502 38.3171 14.299 37.7722 11.0132 36.2525L11.0098 36.2509C7.72542 34.7311 4.94344 32.3008 2.9925 29.2469L2.99015 29.2432C1.04007 26.1891 0.00249603 22.6393 4.49774e-06 19.0131C-0.00136194 17.0243 0.308634 15.0581 0.909868 13.1837C1.45001 13.5364 2.04858 13.8066 2.68786 13.9767C2.1891 15.5996 1.93231 17.2963 1.93349 19.0117C1.93572 22.2694 2.8682 25.4583 4.6207 28.2015L4.62306 28.2052C6.37544 30.9468 8.8738 33.1284 11.8231 34.4924L11.8265 34.494C14.7759 35.8573 18.0528 36.3461 21.2704 35.9027L21.2717 35.9026ZM9.55558 4.77488C10.2688 4.30137 11.0194 3.88061 11.8018 3.51741L11.8052 3.51587C14.7527 2.14848 18.029 1.65512 21.2472 2.09407L21.2485 2.09424C23.622 2.41816 25.8908 3.23924 27.9115 4.49337C28.3588 4.77099 28.794 5.06983 29.2154 5.3892C29.2419 5.40926 29.2691 5.42789 29.2968 5.44509C29.717 5.70543 30.2745 5.63998 30.5993 5.25669C30.9444 4.84948 30.8962 4.23763 30.4728 3.91268C30.4436 3.89028 30.4143 3.86797 30.385 3.84575C29.9314 3.50184 29.4635 3.17928 28.9829 2.87879C26.7196 1.46356 24.1743 0.537543 21.5105 0.17383L21.5093 0.173656C17.9237 -0.31568 14.2732 0.234271 10.9895 1.75851L10.9861 1.76006C10.0451 2.19706 9.14554 2.70885 8.29507 3.28864C8.78909 3.71334 9.21541 4.21492 9.55558 4.77488Z"
      fill="#5E6CB2"
    />
  </svg>
);

import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

export default function NotFoundView() {
  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} direction="column">
        <Stack component="section" direction="column" className="white" justifyContent="center"
          alignItems="center" padding={10} spacing={4}>
          <Typography variant="h2">Oops, This Page Could Not Be Found!</Typography>
          <CrisisAlertIcon sx={{ width: 200, height: 200 }} />
        </Stack>
      </Grid>
    </Box>
  );
}

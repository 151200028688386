import { ThemeProvider } from "@mui/material";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import "./App.css";
import CrispChat from "./components/crisp/crisp";
import { TrackingContextProvider } from "./context/TrackingContext";
import AppLayout from "./layouts/AppLayout";
import { innerTheme, theme } from "./theme";
import ComplaintView from "./views/ComplaintView";
import ContactView from "./views/ContactView";
import FAQsView from "./views/FAQs";
import LandingView from "./views/LandingView";
import NotFoundView from "./views/NotFoundView";
import PrivacyView from "./views/Privacy";
import TermsView from "./views/TermsOfUse";
import EnterpriseView from "./views/EnterpiseView";

const WrapperComponent = () => {
  return (
    <TrackingContextProvider>
      <Outlet />
      <CrispChat />
      <ScrollRestoration />
    </TrackingContextProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<WrapperComponent />}>
      <Route element={<AppLayout />}>
        <Route path="*" element={<NotFoundView />} />
        <Route path="/" element={<LandingView />} />
        <Route path="/enterprise" element={<EnterpriseView />} />
        <Route path="/faqs" element={<FAQsView />} />
        <Route path="/faq" element={<FAQsView />} />
        <Route path="/contact-us" element={<ContactView />} />
        <Route
          path="/contact-us/how-to-make-a-complaint"
          element={<ComplaintView />}
        />
        <Route path="/terms" element={<TermsView />} />
        <Route path="/privacy" element={<PrivacyView />} />
      </Route>

      <Route path="/ai" element={<Navigate to="/" replace={true} />} />
      <Route path="/whats-new" element={<Navigate to="/" replace={true} />} />
      <Route path="/products" element={<Navigate to="/" replace={true} />} />
      <Route
        path="/nucleus-web-services"
        element={<Navigate to="/" replace={true} />}
      />
    </Route>
  )
);

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={innerTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;

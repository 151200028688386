import React, { useEffect } from "react";
interface FrameWrapperProps {
  src: string;
}
export function FrameWrapper(props: FrameWrapperProps) {
  const { src } = props;
  const ref = React.useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState(0);

  function onRecievedMessage(event: MessageEvent) {
    if (event.data.height) setHeight(event.data.height);
  }

  useEffect(function () {
    window.addEventListener("message", onRecievedMessage);

    return function () {
      window.removeEventListener("message", onRecievedMessage);
    };
  });

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      ref={ref}
      id="frame_wrapper"
      sandbox="allow-scripts allow-top-navigation allow-forms allow-same-origin"
      src={src}
      width="100%"
      height={height + "px"}
      scrolling="no"
      frameBorder="0"
      style={{
        maxWidth: 1280,
        width: "100%",
        overflow: "auto",
      }}
    ></iframe>
  );
}
